define([
    'mediaContainer/factory/mediaContainerFactory',
    'mediaContainer/factory/mediaSection',
    'componentsCore',
    'santa-components'
], function (
    mediaContainerFactory,
    mediaSection,
    componentsCore,
    santaComponents
) {
    'use strict';

    const mediaContainer = mediaContainerFactory.createMediaContainer({displayName: 'MediaContainer', useBackgroundDetectionMixin: false}); //eslint-disable-line santa/no-module-state
    const hoverBox = mediaContainerFactory.createMediaContainer({displayName: 'HoverBox', useBackgroundDetectionMixin: false}); //eslint-disable-line santa/no-module-state
    const column = mediaContainerFactory.createMediaContainer({displayName: 'Column', useBackgroundDetectionMixin: true, isDomOnlyUpdateAllowed: false}); //eslint-disable-line santa/no-module-state
    const mediaBox = mediaContainerFactory.createMediaContainer({displayName: 'MediaBox', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state
    const stateBoxState = mediaContainerFactory.createMediaContainer({displayName: 'StateBoxState', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state
    const stateStripState = mediaContainerFactory.createMediaContainer({displayName: 'StateStripState', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state
    const stateBoxFormState = mediaContainerFactory.createMediaContainer({displayName: 'StateBoxFormState', useBackgroundDetectionMixin: true}); //eslint-disable-line santa/no-module-state
    const richContainer = mediaContainerFactory.createMediaContainer({displayName: 'RichContainer', useBackgroundDetectionMixin: false}); //eslint-disable-line santa/no-module-state

    componentsCore.compRegistrar
        .register('wysiwyg.viewer.components.MediaBox', mediaBox)
        .register('wysiwyg.viewer.components.HoverBox', hoverBox)
        .register('wysiwyg.viewer.components.Column', column)
        .register('wysiwyg.viewer.components.StateBoxState', stateBoxState)
        .register('wysiwyg.viewer.components.StateStripState', stateStripState)
        .register('wysiwyg.viewer.components.MediaContainer', mediaContainer)
        .register('wysiwyg.viewer.components.StateBoxFormState', stateBoxFormState)
        .register('wysiwyg.viewer.components.RichContainer', richContainer);

    // Skip new section until santa-components version is updated
    if (mediaSection) {
        componentsCore.compRegistrar.register('responsive.components.Section', mediaSection);
    } else {
        componentsCore.compRegistrar.register('responsive.components.Section', santaComponents.components.Section);
    }


    return {
        hoverBox,
        column,
        mediaContainer,
        mediaContainerFactory
    };
});
