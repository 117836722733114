define([
    'lodash',
    'components',
    'componentsCore',
    'santa-components'
], function (
    _,
    components,
    componentsCore,
    santaComponents
) {
    'use strict';

    // If santa-components version is not up yet do nothing
    if (!santaComponents.factories) {
        return null;
    }

    // Copied getTagNameProp and addTagNameIfParentNotRef from santa-components/containerFactory
    const getTagNameProp = (configTagName, {isParentRefComponent}) => configTagName && !isParentRefComponent && {tagName: configTagName};
    const addTagNameIfParentNotRef = containerFactoryOptions => _.assign(
        containerFactoryOptions, {
            extraPropTypes: {
                isParentRefComponent: santaComponents.santaTypesDefinitions.Component.isParentRefComponent
            },
            getExtraRootProps: props => getTagNameProp(containerFactoryOptions.tagName, props)
        }
    );

    const {containerFactory} = santaComponents.factories;
    const mediaCommon = components.mediaCommon;

    const sectionDefinition = containerFactory(addTagNameIfParentNotRef({
        displayName: 'Section',
        compType: 'responsive.components.Section',
        defaultSkinName: 'wysiwyg.viewer.skins.area.RectangleArea',
        tagName: 'section',
        hasBackground: true
    }));

    // TODO: We might need to add these fixes from mediaContainerFactory, don't know if they are relevant right now:
    // 1. isolation: isolate in inline contaent parent if interations
    // 2. backface-visibility: hidden for components over position fixed
    return _.assign({}, sectionDefinition, {
        propTypes: _.assign({},
            sectionDefinition.propTypes,
            {compDesign: santaComponents.santaTypesDefinitions.Component.compDesign}
        ),
        mixins: [
            ...sectionDefinition.mixins,
            mediaCommon.mediaLogicMixins.fill,
            componentsCore.mixins.createChildComponentMixin
        ],
        statics: _.assign({},
            sectionDefinition.statics,
            {
                behaviors: _.assign({},
                    sectionDefinition.statics.behaviros,
                    mediaCommon.mediaBehaviors.fill
                )
            }
        ),
        getSkinProperties() {
            const def = sectionDefinition.getSkinProperties.apply(this);
            if (!_.isEmpty(this.props.compDesign)) {
                def.bg = this.createFillLayers({
                    mediaDimensions: this.props.mediaDimensions
                });
            }
            return def;
        }
    });
});
